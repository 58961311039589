import styled from 'styled-components';

import { Button, Theme, auraUtils } from 'Framework/UI';

function getAnimationDirection(position, amount) {
  if (position === 'right') {
    return `translate3d(${amount}%, 0, 0)`;
  } else if (position === 'left') {
    return `translate3d(-${amount}%, 0, 0)`;
  }
  return `translate3d(${amount}%, 0, 0)`;
}

function setDirection(props) {
  return getAnimationDirection(props.position, 100);
}

const FlyoutContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;

  &.flyout-enter,
  &.flyout-appear {
    .flyout-overlay {
      opacity: 0;
    }

    .flyout-content {
      transform: ${setDirection};
    }
  }

  &.flyout-enter.flyout-enter-active,
  &.flyout-appear.flyout-appear-active {
    .flyout-overlay {
      opacity: 0.4;
    }

    .flyout-content {
      transform: translate3d(0, 0, 0);
    }
  }

  &.flyout-leave {
    .flyout-overlay {
      opacity: 0.4;
    }

    .flyout-content {
      transform: translate3d(0, 0, 0);
    }
  }

  &.flyout-leave.flyout-leave-active {
    .flyout-content {
      transform: ${setDirection};
    }

    .flyout-overlay {
      opacity: 0;
    }
  }
`;

const FlyoutOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 0;
  background-color: ${({ theme }) => theme.colorDark};
  transition: 0.3s opacity;
  opacity: 0.4;

  ${(props) => Theme.getUserStyles(props, 'overlay', 'flyOut')};
`;

const FlyoutContent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colorLight};
  width: 100%;
  ${({ position }) => `${position}: 0`};
  transform: translate3d(0, 0, 0);

  ${({ width }) => auraUtils.mediaQuery.from(`${width}px`)`
    width: ${width}px;
  `};

  transition: 0.3s transform;

  ${(props) => Theme.getUserStyles(props, 'container', 'flyOut')};
`;

const FlyoutInnerContent = styled.div`
  height: calc(100% - ${({ isShowHeader }) => (isShowHeader ? '62px' : '0px')});
  max-width: 100%;
  padding: 30px 30px 0 30px;
  overflow: auto;
  position: relative;

  ${(props) => Theme.getUserStyles(props, 'content', 'flyOut')};
`;

const FlyoutHeader = styled.div`
  height: 64px;
  padding: 0.7em 42px 1em 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colorGrayLight};
  display: ${({ isShowHeader }) => (isShowHeader ? 'block' : 'none')};

  ${(props) => Theme.getUserStyles(props, 'header', 'flyOut')};
`;

const CloseButton = styled(Button)`
  && {
    position: absolute;
    top: 20px;
    right: 20px;

    .aui-icon-wrapper {
      width: 1.072em;
      height: 1.072em;
    }
  }
`;

export { FlyoutContainer, FlyoutOverlay, FlyoutContent, FlyoutInnerContent, FlyoutHeader, CloseButton };
